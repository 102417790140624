/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
	VStack,
	FormControl,
	SimpleGrid,
	HStack,
	FormLabel,
	Input,
	Button,
	Stack,
	Text,
	Spinner,
} from '@chakra-ui/react'
import { useCreateCustomerAsset } from '../../../hooks/shared.hooks'
import { useForm } from 'react-hook-form'
import { FORM_VIEW } from './otp.config'

export default function CreateDeskView({
	urlParams,
	phoneNumber,
	countryCode,
	setView,
	setAutoSubmit,
	phoneDetail,
}) {
	const { handleSubmit, control } = useForm()
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [isError, setisError] = useState(null)
	const [emailError, setEmailError] = useState(null)
	const [isLoading, setLoading] = useState(false)
	const { mutate: createCustomerAsset } = useCreateCustomerAsset()

	const handleInput = n => e => {
		if (n === 'name') {
			setName(e.target.value)
		} else if (n === 'email') {
			setEmail(e.target.value)
			let value = e.target.value
			const createRegExp = (str, opts) =>
				new RegExp(str.raw[0].replace(/\s/gm, ''), opts || '')
			const regEx = createRegExp`
          ^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|
          (\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|
          (([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$`

			if (!!value) {
				if (!regEx.test(value)) {
					setEmailError('Invalid Email')
				} else {
					setEmailError(null)
				}
			}
		}
	}

	console.log({ phoneNumber, countryCode, phoneDetail })

	const submitForm = () => {
		setLoading(true)
		console.log('urlParams', urlParams)
		createCustomerAsset(
			{
				country_code: !!phoneDetail
					? phoneDetail.country.replace('+', '')
					: countryCode.replace('+', ''),
				phone_number: !!phoneDetail ? phoneDetail.phone : phoneNumber,
				email: email,
				customer_cuid: 'GE' + Math.floor(Math.random() * 50000).toString(),
				asset_name: name,
				gender: '1',
				organization_id: urlParams.organization_id,
				account_id: urlParams.account_id,
				workforce_id: urlParams.workforce_id,
				asset_token_auth: urlParams.asset_token_auth,
				asset_id: urlParams.asset_id,
				auth_asset_id: urlParams?.auth_asset_id,
			},
			{
				onSuccess: async res => {
					if (!!res.message) {
						setisError(res.message)
						setLoading(false)
					} else {
						setisError(null)
						console.log({ res })
						setView(FORM_VIEW.PHONE_VIEW)
						setAutoSubmit(true)
					}
				},
				onError: err => {
					setLoading(false)
				},
			}
		)
	}

	return (
		<SimpleGrid
			spacing={5}
			rounded='lg'
			maxH='500px'
			w='500px'
			bg='white'
			boxShadow='md'
			p={4}
			overflowY='scroll'
			columns={1}
		>
			<VStack w='full'>
				<FormControl boxShadow='md' borderRadius='md' p={2} mb={0}>
					<HStack alignItems='center' justifyContent='space-between' mb={2}>
						<VStack>
							<FormLabel fontWeight='400' fontSize='md' my={1}>
								{'Full Name'}
							</FormLabel>
						</VStack>
					</HStack>
					<Input
						value={name}
						autoFocus={true}
						placeholder='Full Name'
						required
						onChange={handleInput('name')}
						control={control}
					/>
				</FormControl>

				<FormControl boxShadow='md' borderRadius='md' p={2} mb={0}>
					<HStack alignItems='center' justifyContent='space-between' mb={2}>
						<VStack>
							<FormLabel fontWeight='400' fontSize='md' my={1}>
								{'Corporate Email ID'}
							</FormLabel>
						</VStack>
					</HStack>
					<Input
						value={email}
						// autoFocus={true}
						placeholder='Corporate Email ID'
						required
						onChange={handleInput('email')}
						control={control}
					/>
					{emailError && (
						<Stack my={1}>
							<Text color='red.300' fontWeight='400' fontSize='sm'>
								{emailError}
							</Text>
						</Stack>
					)}
				</FormControl>
			</VStack>
			{isError && (
				<Stack my={1}>
					<Text color='red.300' fontWeight='400' fontSize='sm'>
						{isError}
					</Text>
				</Stack>
			)}
			<Button
				mt={4}
				colorScheme={localStorage.getItem('color')}
				bg={localStorage.getItem('color')}
				float='right'
				onClick={() => submitForm()}
				isDisabled={!(!!name && !!email && !emailError && !isLoading)}
			>
				Submit
			</Button>
			{isLoading ? (
				<Stack
					my={4}
					justifyContent='center'
					alignItems='center'
					direction='column'
				>
					<Spinner size='lg' />
					<Text color='brand.800'>Please, wait...</Text>
				</Stack>
			) : null}
		</SimpleGrid>
	)
}
