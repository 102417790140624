import {
	Icon,
	Button,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	Portal,
} from '@chakra-ui/react'
import React from 'react'
import { FaRegCheckCircle, FaRegCircle } from 'react-icons/fa'
import { MdCheckBox, MdCheckBoxOutlineBlank, MdSort } from 'react-icons/md'
import {
	eventLabelTrack,
	sectionDetailedTrack,
	GA_CATEGORY_DASHBOARD,
} from './../../containers/repository/repo.utils'

const CustomMenu = ({
	optionList,
	onSelect,
	icon,
	selected,
	isCheckBox = false,
	isIcon,
	name,
	isFilter,
	closeOnSelect = true,
	menuItemTextProps,
	...iconProps
}) => {
	const isCheck = data => {
		if (!!isCheckBox) {
			return !!selected?.find(i => i.value === data.value)
		} else {
			return data.value === selected?.value
		}
	}

	return (
		<Menu
			zIndex={100}
			id={name ? `${name}-actions` : 'actions'}
			closeOnSelect={closeOnSelect}
		>
			{isIcon ? (
				<MenuButton>
					<Icon as={icon || MdSort} w={6} h={6} />
				</MenuButton>
			) : (
				<MenuButton
					as={IconButton}
					bg={selected?.value ? 'secondary' : 'white'}
					_focus={{
						bg: 'secondary',
					}}
					_hover={{
						bg: 'secondary',
					}}
					border='none'
					borderRadius='md'
					boxShadow='md'
					aria-label='Sort'
					size='md'
					icon={<Icon as={icon || MdSort} w={6} h={6} />}
					variant='outline'
					{...iconProps}
				/>
			)}
			{optionList && !!optionList.length ? (
				<Portal>
					<MenuList zIndex='1500' overflowY='scroll' maxHeight='50vh'>
						{(optionList || []).map((item, i) => {
							if (!!item.isMenu) {
								if (item.hasOwnProperty('disabled') && !!item.disabled) {
									return (
										<MenuItem
											key={Math.random() * Date.now()}
											size='sm'
											py={1}
											icon={item.icon || null}
											isDisabled={true}
											opacity={0.4}
										>
											<Text fontSize='14px' {...menuItemTextProps}>
												{item.label}
											</Text>
										</MenuItem>
									)
								}
								return (
									<MenuItem
										key={Math.random() * Date.now()}
										size='sm'
										py={1}
										icon={item.icon || null}
										onClick={e => {
											eventLabelTrack({
												categoryId: 0,
												actionId: 2,
												label: `MenuItem ${item.label}`,
											})
											item.onClick(e)
										}}
									>
										<Text fontSize='14px' {...menuItemTextProps}>
											{item.label}
										</Text>
									</MenuItem>
								)
							} else {
								return (
									<MenuItem
										key={item.value}
										size='sm'
										py={1}
										icon={
											!!isCheck(item) ? (
												<Icon
													as={isCheckBox ? MdCheckBox : FaRegCheckCircle}
													w={5}
													h={5}
													border='brand.900'
													color='brand.900'
												/>
											) : (
												<Icon
													as={isCheckBox ? MdCheckBoxOutlineBlank : FaRegCircle}
													w={5}
													h={5}
													color='brand.900'
												/>
											)
										}
										onClick={() => {
											sectionDetailedTrack({
												category: GA_CATEGORY_DASHBOARD,
												action: 'DrillDownTable',
												label: 'Select Menu Item',
											})
											onSelect(item, isCheck(item))
										}}
									>
										<Text fontSize='14px' {...menuItemTextProps}>
											{item.label}
										</Text>
									</MenuItem>
								)
							}
						})}
					</MenuList>
				</Portal>
			) : null}
		</Menu>
	)
}

export default CustomMenu
