import {
	Button,
	FormControl,
	FormHelperText,
	FormLabel,
	HStack,
	Spinner,
	Stack,
	Text,
	VStack,
	Link,
} from '@chakra-ui/react'
import { startsWith } from 'lodash'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useSendCognitoOtp } from '../../../hooks/login.hooks'
import { AUTH_LOGIN_MODE } from '../../../utils/constant'
import { LS_LOGIN_MODE } from '../login.util'
import { FORM_VIEW } from './otp.config'

export default function PhoneNumberInput({
	onSendOtpSuccess,
	setView,
	urlData,
	setPhoneNumber,
	setCountryCode,
	phoneNumber,
	countryCode,
	autoSubmit,
}) {
	const { handleSubmit, errors, control } = useForm()
	const { mutate, isLoading, isError } = useSendCognitoOtp()

	useEffect(() => {
		if (autoSubmit && !!phoneNumber) {
			let country = countryCode
			let phone = phoneNumber
			mutate(
				{
					countryCode: country,
					mobile: phone,
				},
				{
					onSuccess: async data => {
						sessionStorage.setItem(AUTH_LOGIN_MODE, LS_LOGIN_MODE.OTP)
						onSendOtpSuccess(data, FORM_VIEW.OTP_VIEW, { country, phone })
					},
					onError: err => {},
				}
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoSubmit])

	const onSave = data => {
		const {
			phone_number: { fv, v },
		} = data
		let splitPhone = fv.toString().split(' ')
		let country = splitPhone[0]
		let phone = v.replace(parseInt(country), '')
		mutate(
			{
				countryCode: country,
				mobile: phone,
			},
			{
				onSuccess: async data => {
					sessionStorage.setItem(AUTH_LOGIN_MODE, LS_LOGIN_MODE.OTP)
					onSendOtpSuccess(data, FORM_VIEW.OTP_VIEW, { country, phone })
				},
				onError: err => {
					if (!!urlData) {
						setPhoneNumber(phone)
						setCountryCode(country)
						setView(FORM_VIEW.CREATE_DESK_VIEW)
					}
				},
			}
		)
	}

	return (
		<form onSubmit={handleSubmit(onSave)}>
			<VStack
				my={4}
				rounded='lg'
				h='250px'
				w='300px'
				bg='white'
				boxShadow='md'
				px={4}
				py={5}
				spacing={8}
				justifyContent='center'
			>
				<FormControl id='phonenumber' pt={2}>
					<FormLabel fontWeight='400' fontSize='md' mb={5}>
						Enter your mobile number
					</FormLabel>
					<Controller
						render={({ field: { onChange, onBlur, value, ref } }) => (
							<PhoneInput
								onChange={(v, c, e, fv) => {
									onChange({ v, fv })
								}}
								onBlur={onBlur}
								country={'in'}
								onlyCountries={[
									'au',
									'in',
									'us',
									'bd',
									'gb',
									'de',
									'fr',
									'lb',
									'hk',
									'sk',
								]}
								countryCodeEditable={false}
								name='phone_number'
								enableSearch
								buttonStyle={{ border: 'none' }}
								buttonClass='rounded '
								inputStyle={{
									maxWidth: '260px',
									margin: 'auto',
									border: 'none',
									boxShadow: '0 2px 6px rgb(0,0,0,0.05)',
								}}
								isValid={(inputNumber, country, countries) => {
									return countries.some(country => {
										return (
											startsWith(inputNumber, country.dialCode) ||
											startsWith(country.dialCode, inputNumber)
										)
									})
								}}
							/>
						)}
						disabled={isLoading}
						name='phone_number'
						control={control}
						value={phoneNumber}
					/>

					{errors && errors['phone'] && (
						<FormHelperText color='red.300' fontWeight='400' fontSize='sm'>
							Please provide valid mobile number
						</FormHelperText>
					)}
				</FormControl>
				<HStack alignItems='center'>
					<Button
						type='submit'
						bg='brand.800'
						color='white'
						size='md'
						minW='200px'
						variant='outline'
						fontWeight='400'
						isDisabled={!!isLoading}
					>
						Send OTP
					</Button>
				</HStack>
				{isError && (
					<Stack my={1}>
						<Text color='red.300' fontWeight='400' fontSize='sm'>
							No user found!
						</Text>
						{/* <Link
              fontSize="md"
              variant="string"
              fontWeight="500"
              color="blue.700"
              onClick={() => {
                setView(FORM_VIEW.CREATE_DESK_VIEW)
              }}
            >
              Create Desk
            </Link> */}
					</Stack>
				)}
				{isLoading ? (
					<Stack
						my={4}
						justifyContent='center'
						alignItems='center'
						direction='column'
					>
						<Spinner size='lg' />
						<Text color='brand.800'>Please, wait...</Text>
					</Stack>
				) : null}
			</VStack>
		</form>
	)
}
