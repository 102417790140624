import React, { useState } from 'react'
import {
  Button,
  Divider,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Icon,
  HStack,
  Text,
} from '@chakra-ui/react'
import { useLanguageContext } from '../../context/locale.context'
import { FaRegCheckCircle, FaRegCircle } from 'react-icons/fa'
import {
  GA_CATEGORY_DIALOG_BOX,
  sectionDetailedTrack,
} from '../../containers/repository/repo.utils'
const MeetingFormDialog = ({ isOpen, onClose, formList, selectedForm }) => {
  const cancelRef = React.useRef()
  const [selected, setSelected] = React.useState()
  const [count, setCount] = React.useState(0)
  const {
    state: { locale },
  } = useLanguageContext()
  const handleCheck = form => {
    setSelected(form.form_id)
    setCount(count + 1)
    setTimeout(() => {
      selectedForm(form)
    }, 1000)
  }
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent borderRadius="sm">
          <AlertDialogHeader fontSize="lg" fontWeight="400">
            {locale['Select Meeting Form']}
          </AlertDialogHeader>
          <Divider />
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {(formList || []).map((form, i) => {
              return (
                <div key={i}>
                  <HStack
                    my={2}
                    onClick={() => {
                      sectionDetailedTrack({
                        category: GA_CATEGORY_DIALOG_BOX,
                        action: 'Meeting Forms Dialog',
                        label: 'Check Form',
                      })
                      handleCheck(form)
                    }}
                  >
                    <Icon
                      as={
                        form.form_id === selected
                          ? FaRegCheckCircle
                          : FaRegCircle
                      }
                      w={6}
                      h={6}
                      border="brand.900"
                      color="brand.900"
                    />
                    <Text>{form.form_name}</Text>
                  </HStack>
                  <Divider />
                </div>
              )
            })}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              borderRadius="sm"
              size="sm"
              variant="outline"
              colorScheme={localStorage.getItem('color')}
              onClick={e => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_DIALOG_BOX,
                  action: 'Meeting Forms Dialog',
                  label: 'Cancel',
                })
                onClose(e)
              }}
            >
              {locale['Cancel']}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
export default MeetingFormDialog
