import { Heading } from '@chakra-ui/react'
import { Amplify } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import 'react-phone-input-2/lib/style.css'
import { FORM_VIEW } from './otp.config'
import OTPNumberInput from './otp-input'
import PhoneNumberInput from './phonenumber'
import { useHistory } from 'react-router-dom'
import { useLoadOrganizationList } from '../../../hooks/asset.hooks'
import { setLocalAuthDetails } from '../../../utils/common.util'
import CreateDeskView from './create-desk'
import { IS_WEBLOGIN_LINK } from '../../../utils/constant'

export function OTPLogin({ urlParams }) {
	const history = useHistory()
	const [view, setView] = useState(FORM_VIEW.PHONE_VIEW)
	const [user, setuser] = useState(null)
	const [error, setError] = useState(null)
	const [phoneDetail, setPhoneDetail] = useState(null)
	const [urlData, setUrlData] = useState(null)
	const [phoneNumber, setPhoneNumber] = useState(null)
	const [countryCode, setCountryCode] = useState(null)
	const [autoSubmit, setAutoSubmit] = useState(false)
	const { mutate } = useLoadOrganizationList()

	useEffect(() => {
		Amplify.configure({
			Auth: {
				mandatorySignIn: true,
				userPoolId: process.env.REACT_APP_OTP_USER_POOL_ID,
				identityPoolId: process.env.REACT_APP_OTP_IDENTITY_POOL_ID,
				userPoolWebClientId: process.env.REACT_APP_OTP_WEB_NO_SECRETE_CLIENT_ID,
				region: process.env.REACT_APP_OTP_AWS_REGION,
			},
			Analytics: {
				disabled: true,
			},
		})
		let url = atob(urlParams?.params)
		let parsed = JSON.parse(url)
		if (!!parsed?.url_form_data) {
			let formData = JSON.parse(parsed.url_form_data)
			setUrlData(formData)
		} else {
			let formData = parsed
			setUrlData(formData)
		}
		localStorage.setItem(IS_WEBLOGIN_LINK, 'true')
	}, [urlParams])

	const queryString = history.location.search
	const params = new URLSearchParams(queryString).get('redirect')

	const onValidationSuccess = () => {
		mutate(
			{},
			{
				onSuccess: async res => {
					const {
						data: { status, response },
					} = res
					if (status === 200) {
						if (response.length > 0) {
							navigateTo(response[0])
						} else {
							setError(`You don't have access to this organization`)
						}
					}
				},
			}
		)
	}

	const navigateTo = org => {
		setLocalAuthDetails(org)
		if (!!params && params.split('-').length > 1) {
			if (params.split('-')[1] === 'email_link') {
				history.push(`/${params.split('-')[1]}/${urlParams.params}`)
			} else {
				history.push(`/${params.split('-').join('/')}/${urlParams.params}`)
			}
		} else {
			history.push(`/originform/entry/${urlParams.params}`)
		}
	}

	if (FORM_VIEW.CREATE_DESK_VIEW === view) {
		return (
			<>
				<Heading fontWeight='300' size='sm' mb={3}>
					Please provide your Full Name and your Corporate Email
				</Heading>
				<CreateDeskView
					setView={setView}
					urlParams={urlData}
					phoneNumber={phoneNumber}
					countryCode={countryCode}
					phoneDetail={phoneDetail}
					setAutoSubmit={setAutoSubmit}
				/>
			</>
		)
	}

	return (
		<>
			<Heading fontWeight='500' size='md'>
				{urlData?.activity_type_welcome_message || `Access Grene Desk via OTP`}
			</Heading>
			{view === FORM_VIEW.PHONE_VIEW ? (
				<PhoneNumberInput
					setView={setView}
					urlData={urlData}
					setPhoneNumber={e => {
						setPhoneNumber(e)
						setAutoSubmit(false)
					}}
					setCountryCode={setCountryCode}
					onSendOtpSuccess={(user, view, data) => {
						setView(view)
						setuser(user)
						setPhoneDetail(data)
					}}
					phoneNumber={phoneNumber}
					countryCode={countryCode}
					autoSubmit={autoSubmit}
				/>
			) : view === FORM_VIEW.OTP_VIEW ? (
				<>
					<OTPNumberInput
						user={user}
						phoneDetail={phoneDetail}
						onValidationSuccess={onValidationSuccess}
						error={error}
						setView={setView}
					/>
				</>
			) : null}
		</>
	)
}
